<template>
	<div>
		<v-toolbar dense class="rounded">
			<v-btn icon class="hidden-xs-only" @click="$router.push('/')">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>

			<v-toolbar-title>TÉRMINOS Y CONDICIONES</v-toolbar-title>

			<v-spacer></v-spacer>
		</v-toolbar>

		<div class="mt-3 rounded white pa-3 text-justify">
			<b>TÉRMINOS Y CONDICIONES DE USO</b>
			<div>
				Los Términos y Condiciones están para regular de forma justa y equilibrada
				nuestra relación con el usuario, redactas de forma unilateral por TRACK AND
				RACES, titular de la página WEB, sin posibilidad que sus usuarios tengan
				capacidad de negociación, dado que se trata de un contrato de adhesión; por
				lo tanto, el usuario podrá hacer uso de este sitio WEB una vez se haya
				registrado y aceptado los términos y condiciones de uso de la misma:
			</div>
			<div>
				<br />
			</div>
			<b>QUIENES SOMOS</b>
			<br />
			<u>Identificación de la Empresa titular del Sitio WEB:</u>
			<div>
				El sitio web www.trackandraces.com, es un servicio online, propiedad de
				<b>SYELTECH SOCIEDAD ANÓNIMA CERRADA - SYELTECH S.A.C.</b>, inscrita en RR.PP, en
				fecha 12 de mayo de 2017, bajo el número de Partida Registral 13875136, con
				Registro Único de Contribuyentes (RUC) Nº 20602215319, domiciliada en
				Santiago de Surco, Lima –Perú, que a los efectos del contenido de estos
				términos y condiciones de uso, y la denominaremos TRACK AND RACE.
			</div>
			<br />
			<b>POSESIÓN DE PROPIEDAD INTELECTUAL Y DERECHOS DE AUTOR</b>
			<div>
				El Servicio y todos los materiales incluidos o transferidos, incluyendo,
				sin limitación, software, imágenes, texto, gráficos, logotipos, patentes,
				marcas registradas, marcas de servicio, derechos de autor, fotografías,
				audio, música y todos los Derechos de Propiedad Intelectual relacionados
				con ellos, son la propiedad exclusiva de TRACK AND RACE. Salvo que se
				indique explícitamente en este documento, no se considerará que nada en
				estos Términos crea una licencia en o bajo ninguno de dichos Derechos de
				Propiedad Intelectual, y el usuario acepta no vender, licenciar, alquilar,
				modificar, distribuir, copiar, reproducir, transmitir, exhibir
				públicamente, realizar públicamente, publicar, adaptar, editar o crear
				trabajos derivados de los mismos.
			</div>
			<div>
				<br />
			</div>
			<b>SERVICIO PRESTADO</b>
			<div>
				En los últimos años, el sector del juego de apuestas vive una auténtica
				revolución tecnológica de la que forma parte el juego online, este se ha
				desarrollado de manera importante no solo en Europa, sino, además, en todo
				el ámbito sudamericano y latinoamericano.
			</div>
			<div>
				En razón de este importante auge, TRACK AND RACES con gran experiencia en
				el mercado de Apuestas Hípicas y Deportivas, incursiona en el juego de
				Apuestas Online, ofreciendo apuestas sobre eventos de carreras de Caballos,
				de Galgos y Carretas, y muy pronto con las Apuestas de Slots, sobre eventos
				de peleas de Gallos, Apuestas deportivas y de los juegos cada vez más
				buscados. El usuario lo puede hacer desde su computadora como desde su
				tablets o Teléfono celular.
			</div>
			<div>
				TRACK AND RACES tiene definidas explícitamente sus reglas y límites de
				apuestas, así como información sobre las promociones vigentes y soporte
				para que puedan apostar con tranquilidad, haciendo su Sitio Web confiable y
				recomendado; ofreciendo además, bonos de bienvenida y de recarga.
			</div>
			<div>
				<br />
			</div>
			<b>APLICACIÓN Y ACEPTACIÓN</b>
			<div>
				1.1.- Los presentes Términos y Condiciones regulan el uso de todos los
				servicios de TRACK AND RACES, incluyendo el sitio web www.trackandraces.com
				y todo su contenido. A su vez contiene varios documentos como los Términos
				y Condiciones , Condiciones Generales y Reglas de todos los Juegos, la
				Política de Privacidad, Registro de cuentas y Autenticidad de Datos,
				Depósitos y Pagos, Reclamaciones y Promociones; por lo tanto.
				<b>
					AL UTILIZAR
					EL SITIO WEB DE TRACK AND RACES (INCLUYENDO SU CONTENIDO) Y/O SERVICIOS,
					ESTÁ DE ACUERDO, ACEPTA Y SE ADHIERE A LOS TÉRMINOS Y CONDICIONES QUE SE
					DETALLAN A CONTINUACIÓN. SI NO ESTÁ DE ACUERDO CON ESTOS TÉRMINOS Y
					CONDICIONES O NO DESEA ESTAR OBLIGADO POR ELLOS, NO UTILICE EL SITIO WEB, O
					LOS SERVICIOS DE TRACK AND RACES.
				</b>
			</div>
			<br />
			<div>
				1.2.- Reserva del derecho de cambiar de oferta: TRACK AND RACES puede sin
				aviso previo cambiar los servicios, dejar de proporcionar los servicios o
				cualquier característica de los servicios que ofrecemos; o crear límites
				para los servicios. Podemos suspender de manera permanente o temporal los
				servicios sin previo aviso ni responsabilidad por cualquier motivo, o sin
				ningún motivo.
			</div>
			<br />
			<div>
				1.3.- Reserva del derecho de aceptar el usuario: TRACK AND RACES se reserva
				el derecho de suspender o rechazar sin previo aviso a cualquier usuario que
				sea sospechoso de: movimientos y/o retiros inusuales en su cuenta de
				apuestas; que tenga cuentas duplicadas; de piratería informática o
				manipulación fraudulenta, participándole de la decisión de suspensión o
				rechazo, través de cualesquiera de los medios proporcionados por el usuario
				al momento de suscribirse.
			</div>
			<br />
			<div>
				1.4.- Forman parte de estos términos y condiciones, tanto las reglas
				generales aplicables a todos los juegos ofrecidos, como las particulares de
				cada juego. Estas Reglas están a disposición del usuario en el Sitio Web y
				las mismas pueden ser modificadas cuando se considere necesario, sin previo
				aviso.
			</div>
			<br />
			<div>
				1.5.- El uso de los servicios por parte del usuario serán únicamente de
				índole personal, en ningún caso con fines comerciales.
			</div>
			<br />
			<div>
				1.6.- Para acceder a nuestro Sitio Web y/o recibir nuestros servicios,
				debes tener al menos dieciocho (18) años de edad, o la mayoría de edad
				legal, el derecho y la libertad para participar en estos Términos como un
				acuerdo vinculante. No tienen permitido utilizar este sitio Web y/o recibir
				servicios, si hacerlo está prohibido en tu país o en virtud de cualquier
				Ley o regulación aplicable a tu caso.
			</div>
			<br />
			<div>
				1.7.- Los términos y condiciones se aplicaran a todo contenido como textos,
				imágenes, archivos, comentarios, etc., enviados o publicados en nuestro
				Sitio Web por el usuario a través de mensajes o blogs del usuario, y que
				atente nuestras políticas y términos; pudiendo ser rechazado o eliminado el
				contenido del usuario, la suspensión del uso de los servicios por parte del
				usuario o la aplicación de cualquier otra medida que consideremos necesaria
				para mantener el uso debido de los servicios y su página.
			</div>
			<br />
			<div>
				1.8.- Nos reservamos el derecho de modificar estos términos de vez en
				cuando a nuestra entera discreción. Por lo tanto, el Usuario Apostador debe
				revisar estas páginas periódicamente. Cuando cambiemos los Términos de una
				manera material, notificaremos al Usuario Apostador que se han realizado
				cambios importantes en los Términos. El uso continuado del Sitio web o
				nuestro servicio por parte del Usuario Apostador después de dicho cambio
				constituye tu aceptación de los nuevos Términos. Si el Usuario Apostador no
				acepta alguno de estos términos o cualquier versión futura de los Términos,
				no use o acceda (o continúe accediendo) a la página web o al servicio.
			</div>
			<div>
				<br />
			</div>
			<b>REGISTRO DE LA CUENTA Y AUTENTICIDAD DE DATOS</b>
			<div>
				1.- Para poder usar nuestros servicios, el apostador primero debe abrir una
				Cuenta de Apostador, registrando sus datos en nuestra página Web. Como
				parte del proceso de registro, deberá aceptar estas Condiciones de Uso,
				Reglamentos y Politicas de Privacidad, y debe tener capacidad legal de
				aceparlas.
			</div>
			<br />
			<div>
				2.- Debe ser mayor de 18 años o más para poder utilizar nuestros servicios,
				abrir una Cuenta de Apostador, usted declara tiene 18 años o más.
			</div>
			<br />
			<div>3.- Puede abrir una sola cuenta de apostador individual.</div>
			<br />
			<div>
				4.- Puede abrir una cuenta de Apuestas, únicamente si es legal en su país
				de Residencia. Al abrir la cuenta de Apuestas, usted declara y nos
				garantiza que la apertura de la cuenta de Apuestas, por su parte no
				infringe ninguna ley ni reglamentación que se aplique a usted. Será
				responsable de cualquier pérdida que incurramos en relación con su
				incumplimiento de esta sección.
			</div>
			<br />
			<div>
				5.- Toda la información que nos proporcione durante el proceso de registro,
				o en cualquier momento posterior, deberá ser precisa, verdadera y
				verificable. Para abrir su cuenta deberá proporcionar su nombre completo,
				fecha de nacimiento, dirección, país de residencia, dirección de correo
				electrónico, número telefónico. Su identidad será validada, en caso de no
				lograrlo se le solicitara que nos proporcione información o documentación
				para tal fin. En caso de que la información suministrada sea falsa o de
				requerirle información y documentación para validar su identidad y se
				niegue a ello, su cuenta podrá ser invalidada.
			</div>
			<br />
			<div>
				6.- Debe asegurarse que la información registrada en su Cuenta de Apuestas
				sea siempre correcta y esté actualizada, y que los datos ingresados al
				registrar la cuenta de apuestas, coincida con su identidad legal y coincida
				con los datos de la cuenta o cuentas que utilizara para depositar o recibir
				dinero.
			</div>
			<br />
			<div>
				7.- Para verificar su identidad, nos reservamos el derecho de utilizar los
				servicios de terceros para autenticar la información de la identidad y la
				cuenta, y para llevar a cabo todas las comprobaciones requeridas necesarias
				en materia de posibles fraudes o para garantizar el cumplimiento de
				nuestras condiciones y reglamentos.
			</div>
			<br />
			<div>
				8.- CUENTA Y CONTRASEÑA: Cuando el Usuario utiliza el Sitio Web, debe
				acceder a su cuenta de apuestas con la dirección de correo electrónico y
				contraseña. Es responsabilidad exclusiva del Usuario:
			</div>
			<br />
			<div>
				a) Mantener la confidencialidad de la dirección de correo electrónico y
				contraseña, siendo el único responsable por todo daño, perjuicio, lesión o
				detrimento que del incumplimiento de esta obligación de confidencialidad se
				genere por cualquier causa.
			</div>
			<br />
			<div>
				b) La Cuenta es personal, única e intransferible, y está prohibido que un
				mismo Usuario Registrado registre o posea más de una Cuenta. En caso que se
				detecte distintas Cuentas que contengan datos coincidentes o relacionados,
				podrá cancelarlas, suspenderlas o inhabilitarlas, a su sola discreción,
				siendo el mismo motivo suficiente para dar de baja al Usuario Registrado,
				incluso en su primera Cuenta.
			</div>
			<br />
			<div>
				c) El Usuario Registrado será responsable por todas las operaciones
				efectuadas desde su Cuenta, pues el acceso a la misma está restringido al
				ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del
				Usuario y cuya confidencialidad es de su exclusiva responsabilidad;
			</div>
			<br />
			<div>d) Actualizar y comprobar frecuentemente su contraseña; y</div>
			<br />
			<div>
				c) Se podrá desactivar la cuenta o contraseña elegida por el usuario o por
				nosotros en caso de determinar que ha incumplido con cualquiera de las
				disposiciones establecidas de estos términos y condiciones; y
			</div>
			<br />
			<div>
				d) En caso de olvidar el usuario o contraseña la misma podría ser
				recuperada a través de las distintas opciones disponibles en la página a
				fin de recuperar dicha información o cambiar la misma.
			</div>
			<div>
				<br />
			</div>
			<b>POLÍTICA DE PRIVACIDAD</b>
			<div>
				<b>TRACK AND RACE</b> procesará toda la información personal y de la tarjeta de
				crédito o débito u otro método de pago, que el Usuario introduzca o
				facilite a través de nuestro Sitio Web de conformidad con la Política de
				Privacidad del Sitio Web.
			</div>
			<div>
				<br />
			</div>
			<b>ACEPTACIÓN DE CONDICIONES</b>
			<div>
				El Usuario al aceptar que ha Leído las condiciones de Uso al momento de
				registrarse, o al dar al clic en el botón Regístrate en el Sitio Web, se
				obliga y acepta que el incumplimiento de cualesquiera de las condiciones de
				uso aquí establecidas, puede acarrear la suspensión o cierre temporal o
				definitivo de la Cuenta de Apuestas, la retención o confiscación de
				depósitos, así como las acciones legales contra el usuario, según sea el
				caso y sea procedente conforme se especifica en las nuestras condiciones de
				uso. Al aceptar estas condiciones al registrarse, no podrá cancelar la
				cuenta.
			</div>
			<div>
				<br />
			</div>
			<b>LIMITES DE DEPÓSITO</b>
			<div>
				Al Usuario de la Cuenta de Apuestas, se le permite fijar límite de la
				cantidad de dinero que desea depositar, ya sea, diario, semanal o mensual.
				Puede asimismo, a su conveniencia restringir su límite, lo cual será de
				aplicación inmediata. Si desea liberar el límite de su depósito, esta
				acción tardará aproximadamente 24 horas para que se haga efectivo. Es
				importante que si tiene dudas en relación a la aplicación de los límites,
				establezca contacto con el Equipo de Atención al usuario.
			</div>
			<div>
				<br />
			</div>
			<b>DEPÓSITOS, PAGOS Y RETIROS</b>
			<br />
			<br />
			<b>DEPÓSITOS</b>
			<div>
				El Usuario Apostador para obtener un saldo y poder realizar apuestas debe
				hacer un depósito o transferencia a las cuentas de banco establecidas en el
				Sitio Web, según el país y tipo de moneda.
			</div>
			<div>
				El Usuario Apostador tiene la opción de tener varias carteras en su CUENTA
				DE USUARIO, según el tipo de moneda. Actualmente nuestro Sitio Web ofrece
				diferentes monedas, las cuales estarán disponibles en su CUENTA.
			</div>
			<div>
				El usuario Apostador una vez realizado el depósito o transferencia, debe
				ingresar a nuestro Sitio Web y a través de su CUENTA DE USUARIO registrar
				el número de depósito o transferencia según el caso, y debe esperar nuestra
				confirmación. Una vez verificado la disponibilidad del monto depositado o
				transferido por el Usuario Apostador, se le consigna dicho monto en la
				CUENTA DE USUARIO según el tipo de moneda utilizada. El Usuario debe
				considerar al momento de depositar o transferir si es de un banco distinto
				en razón del tiempo para que se haga efectivo y estar disponible en la
				CUENTA DE USUARIO.
			</div>
			<div>
				<br />
			</div>
			<b>RETIROS</b>
			<div>
				El Usuario para realizar un retiro de saldo debe ingresar al Sitio Web, y a
				través de su CUENTA DE USUARIO solicitar el retiro en la opción de
				“SOLICITAR PAGO” colocando el monto a retirar. El Administrador de la
				CUENTA DE USUARIO recibirá la información de la solicitud de retiro y se
				realizará una transferencia desde cualquiera de nuestras cuentas a la
				cuenta bancaria registrada en el perfil del Usuario Apostador, y siempre y
				cuando sea el titular de la cuenta. Por ningún motivo se procesará
				solicitudes de Retiros a cuentas que no estén registradas a nombre del
				Usuario Apostador.
			</div>
			<div>
				Las transferencias realizadas a un mismo banco se harán efectivas el mismo
				día, pero cuando estas se hagan a otros bancos estas podrían tardar para
				que se hagan efectivas un lapso de tiempo mayor de hasta Setenta y Dos (72)
				horas, después de realizarla, considerando que si se trata excepcionalmente
				de días feriados y/o bancarios podrían tardar más de Setenta y Dos (72)
				horas, para que se haga efectivo.
			</div>
			<div>
				<br />
			</div>
			<b>PAGO</b>
			<div>
				Los pagos se efectuarán a la mayor brevedad posible una vez procesada la
				solicitud de retiro, puede que pueda producirse retrasos por la
				verificación y revisión de seguridad.
			</div>
			<div>
				Igualmente puede que se retengan el pago en aplicación de alguna
				disposición aquí establecida en los acuerdos.
			</div>
			<div>
				La transferencia bancaria se hará en la cuenta del usuario en un plazo de
				veinticuatros (24) horas.
			</div>
			<div>
				TRACK AND RACE se reserva el derecho de retener cualquier importe de sus
				ganancias para cumplir alguna ley vigente.
			</div>
			<div>
				El usuario Apostador está obligado y será responsable de cumplir con el
				pago de cualquier impuesto relacionado con las ganancias obtenidas.
			</div>
			<div>
				<br />
			</div>
			<b>PROMOCIONES</b>
			<div>
				TRACK AND RACE a fin de incentivar a sus usuarios, podrá semanalmente
				ofrecer diferentes promociones, las cuales se hará del conocimiento al
				público en el sitio Web en la sección PROMOCIONES, donde se establecerá a
				quienes van dirigidos la promoción, por cuanto tiempo será ésta y los
				términos y condiciones de participación de sus usuarios de cada una de
				ellas.
			</div>
			<div>
				TRACK AND RACE se reserva el derecho de modificar, suspender o retirar las
				promociones sin previo aviso.
			</div>
			<div>
				<br />
			</div>
			<b>LIMITACIÓN DE RESPONSABILIDAD</b>
			<div>
				El Usuario de la Cuenta de Apuestas, declara que está en pleno conocimiento
				y acepta que TRACK AND RACE, sus proveedores y licenciatarios, en ningún
				caso será responsable ante el usuario o tercero por daños directos e
				indirectos, punitivos, incidentales, especiales, consecuentes o ejemplares,
				que el Usuario pueda sufrir, indistintamente sea cual fuere la causa que
				dio origen al daño, incluidos, entre otros, daños por pérdida de
				beneficios, ganancias, ingresos, buena voluntad, perdida de datos u otras
				pérdidas intangibles, que surjan de o estén relacionadas con el uso o la
				imposibilidad de utilizar el servicio.
			</div>
			<div>
				TRACK AND RACE no será responsable por cualquier perdida que pueda sufrir
				como consecuencia por cambiar, limitar o dejar de proporcionar los
				servicios o cualquier característica de los servicios que ofrecemos; b.-
			</div>
			<div>
				TRACK AND RACE no asume responsabilidad alguna por: 1.- errores o
				inexactitudes de contenido; 2.- lesiones personales o daños a la propiedad,
				de cualquier naturaleza que sean, como resultado del acceso o uso por parte
				del Usuario de nuestro servicio,( No se responsabilizará por ningún daño
				causado a su equipo informático o a otros bienes, ni de virus que pudieran
				infectarlos, como consecuencia de su acceso al sitio, a su uso o
				navegación, o a la descarga de cualquier software o material desde el
				sitio) ; y 3.- cualquier acceso no autorizado o uso de nuestros servidores
				seguros y / o toda la información personal almacenada en los mismos.
			</div>
			<div>
				<br />
			</div>
			<b>RECLAMACIONES O DISPUTAS</b>
			<div>
				Cualquier disputa que surja en relación a estos términos de uso pueden ser
				referidos a la Comisión de Juego en concordancia con las disposiciones
				pertinentes del Reglamento.
			</div>
			<div>
				Si el Usuario Apostador tiene dudas o reclamos con respecto al uso del
				Sitio Web, incluyendo cualquier duda con respecto al material que aparece
				en el Sitio Web, deberá:
			</div>
			<div>
				En primer lugar, contacte a soporte@trackandraces.com, donde un agente de
				nuestro equipo de Atención al Usuario se va a encargar de su solicitud.
			</div>
			<div>
				En segundo lugar, si su queja o reclamo no pudo ser resuelta con
				satisfacción por nuestro personal de Atención al Cliente, usted puede
				solicitar por escrito a sugerencias@trackandraces.com, que su queja o
				reclamo sea atendida por un Supervisor de Atención al Cliente.
			</div>
			<div>
				<br />
			</div>
			<b>EMAILS DE PROMOCIONES Y CONTENIDO</b>
			<div>
				Acepta recibir de vez en cuando nuestros mensajes y materiales de
				promoción, por correo postal, correo electrónico o cualquier otro
				formulario de contacto que nos proporciones (incluido tu número de teléfono
				para llamadas o mensajes de texto). Si no deseas recibir dichos materiales
				o avisos de promociones, simplemente avísanos en cualquier momento.
			</div>
			<div>
				<br />
			</div>
			<b>JURISDICCIÓN APLICABLE</b>
			<div>
				Estos términos y condiciones se regirán por lo interpretado y analizado en
				conformidad y de acuerdo con las leyes del Estado del Perú.
			</div>
			<div>
				<br />
			</div>

			<b>CONDICIONES GENERALES DE TODOS LOS JUEGOS</b>
			<br />
			<b>SOBRE LAS APUESTAS</b>
			<div>
				1.1.- Todas las reglas, políticas y dividendos publicados en nuestro Sitio
				Web están sujetos a cambios sin previo aviso, de igual forma, los límites
				mínimos y máximos están sujetos a cambios sin previo aviso.
			</div>
			<br />
			<div>
				1.2.- TRACK AND RACES se reserva el derecho de aceptar, limitar o rechazar
				cualquier apuesta antes de realizarla, incluso, podrá ser anulada sin
				previo aviso a consideración de TRACK AND RACES, si una vez realizada la
				apuesta y haya dado inicio el evento, y esta fue realizada con el fin de
				engañar, defraudar, timar, manipular o dañar nuestras transacciones
				comerciales.
			</div>
			<br />
			<div>
				1.3.- Los dividendos correspondientes a las apuestas procesadas de acuerdo
				con el orden oficial definitivo de llegada de cada competencia, se pagarán
				de conformidad hasta los límites de conformidad con lo previsto en las
				Reglas respectivas de cada juego fijadas por TRACK AND RACES.
			</div>
			<br />
			<div>
				1.3.1.- CARRERAS DE CABALLOS: Se tomaran como resultados finales para la
				premiación de los eventos la información oficial de cada uno de los
				Hipódromos.
			</div>
			<br />
			<div>
				El tiempo para la premiación efectiva de los boletos será automático e
				inmediato, una vez los resultados oficiales sean publicados.
			</div>
			<br />
			<div>
				Para eventos suspendidos el monto correspondiente al boleto de la apuesta
				será reintegrado una vez la información oficial sea publicada.
			</div>
			<br />
			<div>
				1.3.2.- CARRERAS DE GALGOS: Se tomaran como resultados finales para la
				premiación de los eventos la información oficial de cada uno de los
				Galgódromos.
			</div>
			<br />
			<div>
				El tiempo para la premiación efectiva de los boletos será automático e
				inmediato, una vez los resultados oficiales sean publicados.
			</div>
			<br />
			<div>
				Para eventos suspendidos el monto correspondiente al boleto de la apuesta
				será reintegrado una vez la información oficial sea publicada.
			</div>
			<br />
			<div>1.3.3.- DEPORTES EN VIVO:</div>
			<br />
			<div>
				1.3.4.- APUESTAS VIRTUALES: Conocida como Deportes Virtuales o Virtual
				Sports. Modalidad de juego donde se apuesta a los deportes de siempre, pero
				en eventos virtuales en competiciones generadas por Ordenador. Los
				resultados de cada evento son expuestos en la plataforma y visibles al
				usuario.
			</div>
			<br />
			<div>
				1.4.- El monto de la apuesta, al igual que los dividendos se expresarán en
				Unidades.
			</div>
			<br />
			<div>
				1.5.- Todas las apuestas deberán ser realizadas antes de que inicie el
				evento, cualquier apuesta realizada después del inicio del evento será
				invalidada.
			</div>
			<br />
			<div>
				1.6.- Los dividendos solo serán acreditados en la cuenta de apuestas del
				usuario ganador, si se acredito dividendos en una apuesta por error, el
				usuario de ésta deberá notificar de inmediato a fin de que se ajuste el
				reembolso. En caso de que el usuario no lo reembolse a TRACK AND RACES,
				procederá a retirarlo automáticamente del saldo de la CUENTA DEL USUARIO.
			</div>
			<div>
				<br />
			</div>
			<div>
				<br />
			</div>
			<b>DE LAS APUESTAS DE CABALLOS Y GALGOS</b>
			<br />
			<b>CLASIFICACIÓN DE LOS HIPÓDROMOS Y/O EVENTOS</b>
			<div>
				<br />
			</div>
			<table class="tabl">
				<thead>
					<tr>
						<th>CLASE</th>
						<th>SEGURIDAD</th>
						<th>DESCRIPCIÓN</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="text-center">
							<h1>A</h1>
						</td>
						<td class="text-center">
							<h3>Seguros</h3>
						</td>
						<td
							class="text-justify"
						>Son hipódromos y/o eventos hípicos que cuentan con una firme estructura y alta reputación, en virtud de que tanto sus oficiales de carreras como la Comisión de Carreras ejercen un estricto control en la operación de los mismos.</td>
					</tr>
					<tr>
						<td class="text-center">
							<h1>B</h1>
						</td>
						<td class="text-center">
							<h3>Inseguros</h3>
						</td>
						<td
							class="text-justify"
						>Son hipódromos y/o eventos hípicos que manejan un mínimo de un millón de dólares promedio diario, lo que dificulta la manipulación de pools. Sin embargo, consideramos que las Comisiones de Carreras de sus estados no son tan sofisticadas para considerarse seguros.</td>
					</tr>
					<tr>
						<td class="text-center">
							<h1>C</h1>
						</td>
						<td class="text-center">
							<h3>Riesgosos</h3>
						</td>
						<td
							class="text-justify"
						>Son hipódromos y/o eventos hípicos de los cuales se decide tomar apuestas porque se consideran muy populares o manejan menos de un millón de dólares, y se toman para proporcionar servicio a la clientela. Sin embargo, se consideran no muy transparentes.</td>
					</tr>
					<tr>
						<td class="text-center">
							<h1>D</h1>
						</td>
						<td class="text-center">
							<h3>Muy Riesgosos</h3>
						</td>
						<td
							class="text-justify"
						>Son hipódromos, eventos hípicos y/o jai alais de los cuales se decide tomar apuestas porque se consideran muy populares y se toman para proporcionar servicio a la clientela. Sin embargo, se consideran pocos transparentes.</td>
					</tr>
				</tbody>
			</table>
			<div>
				<br />
			</div>
			<b>CLASIFICACIÓN DE LOS HIPÓDROMOS Y EVENTOS A LOS FINES DE LOS JUEGOS Y/O APUESTAS</b>
			<div>
				<br />
			</div>
			<table class="tabl">
				<thead>
					<tr>
						<th>CLASE</th>
						<th>HIPÓDROMO</th>
						<th>UBICACIÓN</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td rowspan="16" class="text-center">
							<h1>A</h1>
						</td>
						<td class="text-left">
							<b>AQUEDUCT</b>
						</td>
						<td class="text-justify">Aqueduct Racetrack 110-00 Rockaway Blvd. Jamaica NY 11420</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>BELMONT PARK</b>
						</td>
						<td class="text-center">
							<h3></h3>
						</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>BELMONT STAKES</b>
						</td>

						<td class="text-justify">Cup</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>BETFAIR HOLLYWOOD PARK</b>
						</td>
						<td class="text-justify">1050 S. Prairie Ave. Inglewood, CA 90301 California. USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>BREEDER’S CUP</b>
						</td>
						<td class="text-justify">Cup</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>DEL MAR</b>
						</td>
						<td class="text-justify">2260 Jimmy Durante Boulevard Del Mar, CA 92014, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>DUBAI WORLD CUP</b>
						</td>
						<td class="text-justify">Cup</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>GULFSTREAM</b>
						</td>
						<td class="text-justify">901 S Federal Hwy Hallandale Beach, FL 33009, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>HONG KONG</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>KENTUCKY DERBY</b>
						</td>
						<td class="text-justify">Cup</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>PREAKNESS</b>
						</td>
						<td class="text-justify">Cup</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>SANTA ANITA</b>
						</td>
						<td class="text-justify">Santa Anita Park 285 W Huntington Drive Arcadia CA, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>PIMLICO</b>
						</td>
						<td class="text-justify">
							CONDICIÓN ESPECIAL CATEGORÍA “A”
							<br />“SEGUNDA TRIPLE CORONA”
						</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>CHURCHILL DOWNS</b>
						</td>
						<td class="text-justify">
							700 Central Avenue, Louisville, KY, USA
							<br />CONDICIÓN ESPECIAL CATEGORÍA “A”
							<br />“PRIMERA TRIPLE CORONA”
						</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>KEENELAND</b>
						</td>
						<td class="text-justify">4201 Versailles Rd, Lexington, KY 40510.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>SARATOGA</b>
						</td>
						<td class="text-justify">267 Union Avenue Saratoga Springs, NY 12866, USA.</td>
					</tr>
					<tr>
						<td rowspan="12" class="text-center">
							<h1>B</h1>
						</td>
						<td class="text-left">
							<b>AUSTRALIA</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>ARLINGTON</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>ATLANTIC CITY</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>HIALEAH</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>FAIRGROUNDS</b>
						</td>
						<td class="text-justify">Fair Dr, Fredericksburg, TX 78624.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>FAIRPLEX</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>LONGACRES</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>MONMOUTH PARK</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>GARDEN STATE</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>MEADOWLANDS</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>MEADOWLANDS HARNESS</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>PIMLICO</b>
						</td>
						<td class="text-justify">
							CONDICIÓN ESPECIAL CATEGORÍA “A”
							<br />“SEGUNDA TRIPLE CORONA”
						</td>
					</tr>
					<tr>
						<td rowspan="43" class="text-center">
							<h1>C</h1>
						</td>
						<td class="text-left">
							<b>CHARLES TOWNS</b>
						</td>
						<td class="text-justify">750 Hollywood Drive Charles Town, WV 25414, USA</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>CHURCHILL DOWNS</b>
						</td>
						<td class="text-justify">
							700 Central Avenue, Louisville, KY, USA
							<br />CONDICION ESPECIAL CATEGORIA “A”
							<br />“PRIMERA TRIPLE CORONA”
						</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>DELAWARE PARK</b>
						</td>
						<td class="text-justify">Delaware Park Boulevard Wilmington, DE 19804, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>DELTA DOWN</b>
						</td>
						<td class="text-justify">2717 Delta Downs Dr Vinton, LA 70668, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>ELLIS PARK</b>
						</td>
						<td class="text-justify">ELLIS PARK, 3300 U.S 41, HENDERSON, KY.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>EVANGELINE DOWNS</b>
						</td>
						<td class="text-justify">2235 Creswell Lane Extension Opelousas, LA 70570, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>FINGER LAKES</b>
						</td>
						<td class="text-justify">5857 New York 96 Farmington, NY 14425, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>GOLDEN GATE FIELDS</b>
						</td>
						<td class="text-justify">1100 Eastshore Frontage Road Berkeley, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>INDIANA DOWNS</b>
						</td>
						<td class="text-justify">4200 Michigan Road, Shelbyville, IN 46176. State: Indiana. USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>LAUREL PARK</b>
						</td>
						<td class="text-justify">198 Laurel Race Track Rd, Laurel, MD 20724, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>LOS ALAMITOS</b>
						</td>
						<td class="text-justify">4961 Katella Avenue Los Alamitos, CA 90720, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>LOUISIANA DOWNS</b>
						</td>
						<td class="text-justify">8000 E Texas St, Bossier City, LA. USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>PENN NATIONAL</b>
						</td>
						<td class="text-justify">777 Hollywood Blvd Grantville, PA, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>ARAPAHOE PARK</b>
						</td>
						<td class="text-justify">Qunci Av, Aurora, CO 80016, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>BALMORAL</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>BAY MEADOWS</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>EMERALD DOWNS</b>
						</td>
						<td class="text-justify">2300 Emerald Downs Drive Auburn, WA 98001, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>F FRESNO</b>
						</td>
						<td class="text-justify">1121 S. Chance Avenue Fresno, CA 93702.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>F SAN MATEO EN BAY</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>MEADOWS</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>F PLEASANTON</b>
						</td>
						<td class="text-justify">1121 S. Chance Avenue Fresno, CA 93702.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>F SACRAMENTO</b>
						</td>
						<td class="text-justify">1600 Exposition Blvd. Sacramento, CA 95815.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>F SANTA ROSA</b>
						</td>
						<td class="text-justify">1350 BENNETT VALLEY ROAD - SANTA ROSA, CA 95404.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>F STOCKTON</b>
						</td>
						<td class="text-justify">1121 S. Chance Avenue Fresno, CA 93702.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>F VALLEJO</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>FAIRMOUNT PARK</b>
						</td>
						<td class="text-justify">9301 Collinsville Rd Collinsville, IL 62234,USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>FORT ERIE</b>
						</td>
						<td class="text-justify">230 Catherine, Fort Erie, ON L2A 2K9, Canada.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>HAWTHORNE</b>
						</td>
						<td class="text-justify">3501 S Laramie Ave, Cicero, IL 60804.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>LONE STAR PARK</b>
						</td>
						<td class="text-justify">1000 Lone Star Parkway Grand Prairie, TX 75050, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>MAYWOOD PARK HARNESS</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>MOUNTAINEER</b>
						</td>
						<td class="text-justify">1420 Mountaineer Circle.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>PRAIRIE MEADOWS</b>
						</td>
						<td class="text-justify">1 Prairie Meadows Drive Altoona, IA 50009, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>RAYNHAM PARK</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>REMINGTON PARK</b>
						</td>
						<td class="text-justify">1 Remington Place Oklahoma City, OK 73111, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>RETAMA PARK</b>
						</td>
						<td class="text-justify">1 Retama Pkwy, Selma, TX 78154.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>SAM HOUSTON PARK</b>
						</td>
						<td class="text-justify">7575 North Sam Houston Pkwy W, Houston, TX 77064.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>SPORTSMAN’S PARK</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-left">
							<b>SUFFOLK DOWNS</b>
						</td>
						<td class="text-justify">111 Waldemar Ave, Boston, MA 02128.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>THISTLEDOWN</b>
						</td>
						<td class="text-justify">21501 Emery Road Cleveland, OH 44128, USA.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>TURF PARADISE</b>
						</td>
						<td class="text-justify">1501 W Bell Rd, Phoenix, AZ 85023.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>TURFWAY PARK</b>
						</td>
						<td class="text-justify">7500 Turfway Rd, Florence, KY 41042.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>WOODBINE</b>
						</td>
						<td class="text-justify">Rexdale Boulevard Toronto, ON M9W 5L2, Canada.</td>
					</tr>
					<tr>
						<td class="text-left">
							<b>YAVAPAI DOWNS</b>
						</td>
						<td class="text-justify"></td>
					</tr>
					<tr>
						<td class="text-center" colspan="3">
							<b>
								<u>TODO HIPÓDROMO O EVENTO NO CONTEMPLADO EN ESTAS CLASIFICACIONES SON CONSIDERADOS TIPO “C” Y TENDRÁN LOS LÍMITES DE PAGOS Y PAGOS MÁXIMOS CORRESPONDIENTE AL MISMO.</u>
							</b>
						</td>
					</tr>
				</tbody>
			</table>
			<div>
				<br />
			</div>
			<div>
				<b>REGLAS Y LIMITES APLICABLES DE ACUERDO A LA CLASIFICACIÓN DE LOS HIPÓDROMOS</b>
			</div>
			<br />
			<table class="tabl">
				<thead>
					<tr>
						<th class="text-center">HIPÓDROMOS CLASE “A”</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<table class="tabl">
								<thead>
									<tr>
										<th class="text-center" colspan="2">Apuestas Derechas</th>
									</tr>
								</thead>
								<tbody style="background: #dbf4ff;">
									<tr>
										<td class="text-center">
											<b>Ganador</b>
										</td>
										<td>El apostador cobrará a Ganador si su ejemplar finalizó oficialmente en primer lugar.</td>
									</tr>
									<tr>
										<td class="text-center">
											<b>Place</b>
										</td>
										<td>El apostador cobrará a Place si su ejemplar finalizó oficialmente en 1º o 2º lugar.</td>
									</tr>
									<tr>
										<td class="text-center">
											<b>Show</b>
										</td>
										<td>El apostador cobrará a Show si su ejemplar finalizó oficialmente en 1º, 2º o 3º lugar.</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td colspan="2" class="text-left">
							<b>
								<ul>
									<li>Entries y fields (llaves) corren bajo un solo interés.</li>
									<li>En una carrera con tres (3) ejemplares, no habrá apuesta de PLACE, y se reembolsará el Dinero.</li>
									<li>En una carrera con cuatro (4) ejemplares, no habrá apuestas de SHOW, y se reembolsará el Dinero.</li>
									<li>No hay Limite de pago</li>
								</ul>
							</b>
						</td>
					</tr>
					<tr>
						<td>
							<table class="tabl">
								<thead>
									<tr>
										<th class="text-center" colspan="2">Exóticas</th>
									</tr>
								</thead>
								<tbody>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Quiniela</b>
										</td>
										<td>El apostador cobrará la apuesta de quiniela si selecciona a los ejemplares ganadores del primer y segundo lugar en cualquier orden de llegada.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>No aplica el límite de pago, excepto que el boleto virtual exceda a lo establecido por nosotros como pago máximo, que en la apuesta de QUINIELA es de diez mil unidades (10.000 U.) por boleto virtual, sin importar las veces apostadas en el mismo.</li>
													<li>Si un field o entry (misma llave) finalizan en primer y segundo lugar, la combinación ganadora será el field y el ejemplar que finalice en tercer lugar.</li>
													<li>Si su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si hay un entry o field de tres (3) o más ejemplares, no habrá apuesta de quiniela.</li>
													<li>No hay pagos de consolación.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará 500 Unidades por cada Unidad apostada en los boletos virtuales con la combinación acertada, siendo igualmente aplicable el pago máximo.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Exacta</b>
										</td>
										<td>El apostador cobrará la apuesta de exacta si selecciona a los ejemplares ganadores del primer y segundo lugar en el orden exacto de llegada.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>No aplica el límite de pago, excepto que el boleto virtual exceda a lo establecido por nosotros como pago máximo, que en la apuesta de QUINIELA es de diez mil unidades (10.000 U.) por boleto virtual, sin importar las veces apostadas en el mismo.</li>
													<li>Si un field o entry (misma llave) termina en primer y segundo lugar, la combinación ganadora será el field y el ejemplar que llegue a tercer lugar.</li>
													<li>Si su ejemplar es retirado, el dinero de la apuesta será reembolsado, y no habrá pagos de consolación.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará 500 Unidades por cada Unidad apostada en los boletos virtuales con la combinación acertada, siendo igualmente aplicable el pago máximo.</li>
													<li>En una carrera con cuatro (4) o menos ejemplares, no habrá apuesta de EXACTA y será reembolsado el dinero.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Doble Selección (Pick 2)</b>
										</td>
										<td>El apostador cobrará la apuesta de doble selección si selecciona al ejemplar ganador del primer lugar en dos carreras consecutivas designadas con apuesta de doble selección.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>No aplica el límite de pago, excepto que el boleto virtual exceda a lo establecido por nosotros como pago máximo, que en la apuesta de DOBLE SELECCIÓN (PICK 2) es de diez mil unidades (10.000 U.) por boleto virtual, sin importar las veces apostadas en el mismo.</li>
													<li>Si su ejemplar es retirado de cualquiera de las dos (2) carreras designadas como «doble selección», el dinero de la apuesta será reembolsado, y no habrá pagos de consolación.</li>
													<li>No hay pagos de consolación.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará 500 Unidades por cada Unidad apostada en los boletos virtuales con la combinación acertada, siendo igualmente aplicable el pago máximo.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (ejemplo: problemas de energía eléctrica, cambio de pista, etc.), el dinero de la apuesta será reembolsado.</u>
													</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Trifecta</b>
										</td>
										<td>El apostador cobrará la apuesta de Trifecta si selecciona a los ejemplares ganadores del primer, segundo y tercer lugar en el orden exacto de llegada, en las carreras designadas con apuesta de Trifecta.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>No aplica el límite de pago, excepto que el boleto virtual exceda a lo establecido por nosotros como pago máximo, que en la apuesta de TRIFECTA es de quince mil unidades (15.000 U.) por boleto virtual, sin importar las veces apostadas en el mismo.</li>
													<li>Si en el evento su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo de origen paga con todos los ejemplares participantes en cualquiera de los tres (3) lugares, por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos de origen, no siendo aplicable por nuestro Sitio Web el pago de este premio.</li>
													<li>No hay pagos de consolación.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará 3.000 Unidades por cada Unidad apostada en los boletos virtuales con la combinación acertada, siendo igualmente aplicable el pago máximo.</li>
													<li>En una carrera con cinco (5) o menos ejemplares, no habrá apuesta de TRIFECTA, y será reembolsado el Dinero.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Triple Selección (Pick 3)</b>
										</td>
										<td>El apostador cobrará la apuesta de triple selección si selecciona al ejemplar ganador del primer lugar en tres carreras consecutivas designadas con apuesta de triple selección.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>No aplica el límite de pago, excepto que el boleto virtual exceda a lo establecido por nosotros como pago máximo, que en la apuesta de PICK 3 es de veinte mil unidades (20.000 U.) por boleto virtual, sin importar las veces apostadas en el mismo.</li>
													<li>Si su ejemplar es retirado del evento, éste será sustituido por el primer ejemplar favorito en el Hipódromo de origen al cierre de la carrera; y en caso, de que el ejemplar favorito resulte ganador, se pagará por el favorito ganador y por cada ejemplar retirado del evento. No habrá reembolsos por los retirados.</li>
													<li>En caso de que una o más carreras sean canceladas, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo de origen paga con todos los ejemplares participantes en cualquiera de las tres (3) carreras, por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos, no siendo aplicable por el Sitio Web el pago de este premio.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo, no habrá premio de consolación, solo se pagará 3.000 Unidades por cada Unidad apostada en los boletos virtuales con la combinación acertada, siendo igualmente aplicable el pago máximo.</li>
													<li>No hay acumulados.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (problemas de energía eléctrica, cambio de pista, etc.), y el hipódromo de origen pagare con todos los ejemplares participantes, nuestro Sitio Web de apuestas aplicará:</u>
														<ol>
															<li>Los resultados de pago del hipódromo de origen;</li>
															<li>Solo pagará los tickets con la combinación acertada;</li>
															<li>Pagará los ejemplares adicionales que hayan sido seleccionados por el apostador en la carrera donde hubo la circunstancia o situación no prevista.</li>
														</ol>
													</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Superfecta</b>
										</td>
										<td>El apostador cobrará la apuesta de Superfecta si selecciona a los ejemplares ganadores del primer, segundo, tercero y cuarto lugar, en el orden exacto de llegada, en carreras designadas con apuesta de Superfecta.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>No aplica el límite de pago, excepto que el boleto virtual exceda a lo establecido por nosotros como pago máximo, que en la apuesta de SUPERFECTA es de veinticinco mil unidades (25.000 U.) por boleto virtual, sin importar las veces apostadas en el mismo.</li>
													<li>Si en el evento su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo de origen paga con todos los ejemplares participantes en cualquiera de los cuatro (4) lugares, por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos de origen, no siendo aplicable por nuestro Sitio Web el pago de este premio.</li>
													<li>No hay pagos de consolación.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará 4.500 Unidades por cada Unidad apostada en los boletos virtuales con la combinación acertada, siendo igualmente aplicable el pago máximo.</li>
													<li>En una carrera con seis (6) o menos ejemplares, no habrá apuesta de SUPERFECTA, y se reembolsará el dinero.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Pick 4</b>
										</td>
										<td>El apostador cobrará la apuesta de Pick 4 si selecciona al ejemplar ganador del primer lugar en cuatro carreras consecutivas designadas con apuesta de pick 4.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>No aplica el límite de pago, excepto que el boleto virtual exceda a lo establecido por nosotros como pago máximo, que en la apuesta de PICK 4 es de treinta mil unidades (30.000 U.) por boleto virtual, sin importar las veces apostadas en el mismo.</li>
													<li>No habrá reembolsos por los retirados.</li>
													<li>Si su ejemplar es retirado del evento, éste será sustituido por el primer ejemplar favorito en el Hipódromo de origen al cierre de la carrera; y en caso, de que el ejemplar favorito resulte ganador, se pagará por el favorito ganador y por cada ejemplar retirado del evento.</li>
													<li>En caso de que una o más carreras sean canceladas, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo de origen paga con todos los ejemplares participantes, en cualquiera de las cuatro (4) carreras por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos de origen, no siendo aplicable por nuestro Sitio Web el pago de este premio.</li>
													<li>No hay pagos de consolación.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará 4.500 Unidades por cada Unidad apostada en los boletos virtuales con la combinación acertada, siendo igualmente aplicable el pago máximo.</li>
													<li>No hay acumulados.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (problemas de energía eléctrica, cambio de pista, etc.), y el hipódromo de origen pagare con todos los ejemplares participantes, nuestro Sitio Web de apuestas aplicará:</u>
														<ol>
															<li>Los resultados de pago del hipódromo de origen;</li>
															<li>Solo pagará los tickets con la combinación acertada;</li>
															<li>Pagará los ejemplares adicionales que hayan sido seleccionados por el apostador en la carrera donde hubo la circunstancia o situación no prevista.</li>
														</ol>
													</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Parlay</b>
										</td>
										<td>El apostador cobrará la apuesta de PARLAY si selecciona a dos o tres ejemplares en diferentes carreras y/o diferentes hipódromos y finalizan ganadores. La apuesta debe ser, ya sea a primer, segundo o tercer lugar, pero todos los ejemplares seleccionados deben estar en el mismo orden de apuesta, y las selecciones deberán finalizar en el orden elegido.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>
														Los límites de pagos en Parlay se figuran de la siguiente manera:
														<ul>
															<li>Primer lugar: 20 unidades por cada unidad apostada.</li>
															<li>Segundo lugar: 8 unidades por cada unidad apostada.</li>
															<li>Tercer lugar: 4 unidades por cada unidad apostada.</li>
														</ul>
													</li>
													<li>El límite de pagos es de ciento cincuenta unidades (150 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual de parlay es de diez mil unidades (10.000 U.).</li>
													<li>El máximo número de ejemplares en un parlay es de tres (3), y el mínimo dos (2)</li>
													<li>Si su ejemplar es retirado, un parlay de tres (3) se convierte en un parlay de dos (2), y un parlay de dos (2) se convierte en apuesta derecha</li>
													<li>Entries y fields corren bajo un solo interés</li>
												</ul>
											</b>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
			<table class="tabl">
				<thead>
					<tr>
						<th class="text-center">HIPÓDROMOS CLASE “B”</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<table class="tabl">
								<thead>
									<tr>
										<th class="text-center" colspan="2">Apuestas Derechas</th>
									</tr>
								</thead>
								<tbody style="background: #dbf4ff;">
									<tr>
										<td class="text-center">
											<b>Ganador</b>
										</td>
										<td>El apostador cobrará a Ganador si su ejemplar finalizó oficialmente en primer lugar.</td>
									</tr>
									<tr>
										<td class="text-center">
											<b>Place</b>
										</td>
										<td>El apostador cobrará a Place si su ejemplar finalizó oficialmente en 1º o 2º lugar.</td>
									</tr>
									<tr>
										<td class="text-center">
											<b>Show</b>
										</td>
										<td>El apostador cobrará a Show si su ejemplar finalizó oficialmente en 1º, 2º o 3º lugar.</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td colspan="2" class="text-left">
							<b>
								<ul>
									<li>Entries y fields (llaves) corren bajo un solo interés.</li>
									<li>En una carrera con tres (3) ejemplares, no habrá apuesta de PLACE, y se reembolsará el Dinero.</li>
									<li>En una carrera con cuatro (4) ejemplares, no habrá apuestas de SHOW, y se reembolsará el Dinero.</li>
									<li>El pago máximo por TICKET es de diez mil unidades (10.000U.), sin importar las veces apostadas en el mismo boleto.</li>
									<li>Si su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
									<li>
										El pago se hará de la siguiente manera:
										<ul>
											<li>Primer lugar: 30 unidades por cada unidad apostada.</li>
											<li>Segundo lugar: 12 unidades por cada unidad apostada.</li>
											<li>Tercer lugar: 6 unidades por cada unidad apostada.</li>
										</ul>
									</li>
									<li>
										Para carreras de caballos dosañeros, los límites de pago son:
										<ul>
											<li>Primer lugar: 30 unidades por cada unidad apostada.</li>
											<li>Segundo lugar: 12 unidades por cada unidad apostada.</li>
											<li>Tercer lugar: 6 unidades por cada unidad apostada.</li>
										</ul>
									</li>
								</ul>
							</b>
						</td>
					</tr>
					<tr>
						<td>
							<table class="tabl">
								<thead>
									<tr>
										<th class="text-center" colspan="2">Exóticas</th>
									</tr>
								</thead>
								<tbody>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Quiniela</b>
										</td>
										<td>El apostador cobrará la apuesta de quiniela si selecciona a los ejemplares ganadores del primer y segundo lugar en cualquier orden de llegada.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es trescientas unidades (300 U.) por cada unidad apostada.</li>
													<li>El pago máximo por Boleto virtual es de diez mil unidades (10.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si un field o entry (misma llave) finaliza en primer y segundo lugar, la combinación ganadora serán el field y el ejemplar que finalice en tercer lugar.</li>
													<li>Si su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si hay un entry o field de tres (3) o más ejemplares, no habrá apuesta de quiniela.</li>
													<li>No hay pagos de consolación.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Exacta</b>
										</td>
										<td>El apostador cobrará la apuesta de exacta si selecciona a los ejemplares ganadores del primer y segundo lugar en el orden exacto de llegada.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es trescientas unidades (300 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de diez mil unidades (10.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si un field o entry termina en primer y segundo lugar, la combinación ganadora será el field y el ejemplar que llegue a tercer lugar.</li>
													<li>Si su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En una carrera con cuatro (4) o menos ejemplares, no habrá apuesta de EXACTA y será reembolsado el Dinero.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Doble Selección (Pick 2)</b>
										</td>
										<td>El apostador cobrará la apuesta de doble selección si selecciona al ejemplar ganador del primer lugar en dos carreras consecutivas designadas con apuesta de doble selección.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es trescientas unidades (300 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de diez mil unidades (10.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si su ejemplar es retirado de cualquiera de las dos (2) carreras designadas como «doble selección», el dinero de la apuesta será reembolsado, y no habrá pagos de consolación.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (ejemplo: problemas de energía eléctrica, cambio de pista, etc.), el dinero de la apuesta será reembolsado.</u>
													</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Trifecta</b>
										</td>
										<td>El apostador cobrará la apuesta de Trifecta si selecciona a los ejemplares ganadores del primer, segundo y tercer lugar en el orden exacto de llegada, en las carreras designadas con apuesta de Trifecta.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es dos mil quinientas unidades (2.500 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de quince mil unidades (15.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si en el evento su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo de origen paga con todos los ejemplares participantes en cualquiera de los tres (3) lugares, por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos de origen, no siendo aplicable el pago de este premio en nuestro Sitio Web de apuestas.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En una carrera con cinco (5) o menos ejemplares, no habrá apuesta de TRIFECTA, y se reembolsará el dinero.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Triple Selección (Pick 3)</b>
										</td>
										<td>El apostador cobrará la apuesta de triple selección si selecciona al ejemplar ganador del primer lugar en tres carreras consecutivas designadas con apuesta de triple selección.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es dos mil quinientas unidades (2.500 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de quince mil unidades (15.000U.), sin importar las veces apostadas en el boleto.</li>
													<li>No habrá reembolsos por los retirados.</li>
													<li>Si su ejemplar es retirado del evento, éste será sustituido por el primer ejemplar favorito del Hipódromo de origen al cierre de la carrera; y en caso, de que el ejemplar favorito resulte ganador, se pagará por el favorito ganador y por cada ejemplar retirado del evento.</li>
													<li>En caso de que una o más carreras sean canceladas, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo de origen paga con todos los ejemplares participantes en cualquiera de las tres (3) carreras, por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos de origen, no siendo aplicable el pago de este premio en nuestro Sitio Web de apuestas.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>No hay acumulados.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (ejemplo: problemas de energía eléctrica, cambio de pista, etc.), y el hipódromo de origen pagare con todos los ejemplares participantes, nuestro Sitio Web de apuestas aplicará:</u>
														<ol>
															<li>Los resultados de pago del hipódromo de origen;</li>
															<li>Solo pagará los tickets con la combinación acertada;</li>
															<li>
																Pagará los ejemplares adicionales que hayan sido seleccionados por el apostador en la carrera donde
																hubo cambio de pista.
															</li>
														</ol>
													</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Superfecta</b>
										</td>
										<td>El apostador cobrará la apuesta de Superfecta si selecciona a los ejemplares ganadores del primer, segundo, tercero y cuarto lugar, en el orden exacto de llegada, en carreras designadas con apuesta de Superfecta.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es de cuatro mil unidades (4.000 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de veinte mil unidades (20.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si en el evento su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo de origen paga con todos los ejemplares participantes en cualquiera de los cuatro (4) lugares, por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos de origen, no siendo aplicable el pago de este premio en nuestro Sitio Web de apuestas.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En una carrera con seis (6) o menos ejemplares, no habrá apuesta de SUPERFECTA, y se reembolsará el dinero.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Pick 4</b>
										</td>
										<td>El apostador cobrará la apuesta de Pick 4 si selecciona al ejemplar ganador del primer lugar en cuatro carreras consecutivas designadas con apuesta de pick 4.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es cuatro mil unidades (4.000 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de veinte mil unidades (20.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>No habrá reembolsos por los retirados.</li>
													<li>Si su ejemplar es retirado del evento, éste será sustituido por el primer ejemplar favorito del hipódromo de origen al cierre de la carrera; y en caso, de que el ejemplar favorito resulte ganador, se pagará por el favorito ganador y por cada ejemplar retirado del evento.</li>
													<li>En caso de que una o más carreras sean canceladas, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo de origen paga con todos los ejemplares participantes en cualquiera de las cuatro carreras, por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos de origen, no siendo aplicable el pago de este premio en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>No hay acumulados.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (ejemplo: problemas de energía eléctrica, cambio de pista, etc.), y el hipódromo de origen pagare con todos los ejemplares participantes, nuestro Sitio Web de apuestas aplicará:</u>
														<ol>
															<li>Los resultados de pago del hipódromo de origen;</li>
															<li>Solo pagará los tickets con la combinación acertada;</li>
															<li>Pagará los ejemplares adicionales que hayan sido seleccionados por el apostador en la carrera donde hubo cambio de pista.</li>
														</ol>
													</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Parlay</b>
										</td>
										<td>El apostador cobrará la apuesta de PARLAY si selecciona a dos o tres ejemplares en diferentes carreras y/o diferentes hipódromos y finalizan ganadores. La apuesta debe ser, ya sea a primer, segundo o tercer lugar, pero todos los ejemplares seleccionados deben estar en el mismo orden de apuesta, y las selecciones deberán finalizar en el orden elegido.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>
														Los límites de pagos en Parlay se figuran de la siguiente manera:
														<ul>
															<li>Primer lugar: 20 unidades por cada unidad apostada.</li>
															<li>Segundo lugar: 8 unidades por cada unidad apostada.</li>
															<li>Tercer lugar: 4 unidades por cada unidad apostada.</li>
														</ul>
													</li>
													<li>El límite de pagos es de ciento cincuenta unidades (150 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual de parlay es de 6.000 Unidades.</li>
													<li>El máximo número de ejemplares en un parlay es de tres (3), y el mínimo dos (2)</li>
													<li>Si su ejemplar es retirado, un parlay de tres (3) se convierte en un parlay de dos (2), y un parlay de dos (2) se convierte en apuesta derecha</li>
													<li>Entries y fields corren bajo un solo interés</li>
												</ul>
											</b>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
			<table class="tabl">
				<thead>
					<tr>
						<th class="text-center">HIPÓDROMOS CLASE “C”</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<table class="tabl">
								<thead>
									<tr>
										<th class="text-center" colspan="2">Apuestas Derechas</th>
									</tr>
								</thead>
								<tbody style="background: #dbf4ff;">
									<tr>
										<td class="text-center">
											<b>Ganador</b>
										</td>
										<td>El apostador cobrará a Ganador si su ejemplar finalizó oficialmente en primer lugar.</td>
									</tr>
									<tr>
										<td class="text-center">
											<b>Place</b>
										</td>
										<td>El apostador cobrará a Place si su ejemplar finalizó oficialmente en 1º o 2º lugar.</td>
									</tr>
									<tr>
										<td class="text-center">
											<b>Show</b>
										</td>
										<td>El apostador cobrará a Show si su ejemplar finalizó oficialmente en 1º, 2º o 3º lugar.</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td colspan="2" class="text-left">
							<b>
								<ul>
									<li>Entries y fields (llaves) corren bajo un solo interés.</li>
									<li>En una carrera con tres (3) ejemplares, no habrá apuesta de PLACE, y se reembolsará el Dinero.</li>
									<li>En una carrera con cuatro (4) ejemplares, no habrá apuestas de SHOW, y se reembolsará el Dinero.</li>
									<li>El pago máximo por TICKET es de diez mil unidades (10.000U.), sin importar las veces apostadas en el mismo boleto.</li>
									<li>Si su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
									<li>
										El pago se hará de la siguiente manera:
										<ul>
											<li>Primer lugar: 20 unidades por cada unidad apostada.</li>
											<li>Segundo lugar: 8 unidades por cada unidad apostada.</li>
											<li>Tercer lugar: 4 unidades por cada unidad apostada.</li>
										</ul>
									</li>
									<li>
										Para carreras de caballos dosañeros, los límites de pago son:
										<ul>
											<li>Primer lugar: 20 unidades por cada unidad apostada.</li>
											<li>Segundo lugar: 8 unidades por cada unidad apostada.</li>
											<li>Tercer lugar: 4 unidades por cada unidad apostada.</li>
										</ul>
									</li>
								</ul>
							</b>
						</td>
					</tr>
					<tr>
						<td>
							<table class="tabl">
								<thead>
									<tr>
										<th class="text-center" colspan="2">Exóticas</th>
									</tr>
								</thead>
								<tbody>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Quiniela</b>
										</td>
										<td>El apostador cobrará la apuesta de quiniela si selecciona a los ejemplares ganadores del primer y segundo lugar en cualquier orden de llegada.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es ciento cincuenta Unidades (150 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de seis mil unidades (6.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si un field o entry (misma llave) finaliza en primer y segundo lugar, la combinación ganadora será el field y el ejemplar que finalice en tercer lugar.</li>
													<li>Si su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si hay un entry o field de tres (3) o más ejemplares, no habrá apuesta de quiniela.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Exacta</b>
										</td>
										<td>El apostador cobrará la apuesta de exacta si selecciona a los ejemplares ganadores del primer y segundo lugar en el orden exacto de llegada.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es trescientas unidades (300 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de seis mil unidades (6.000 U.), sin importar las veces apostadas en el boleto</li>
													<li>Si un field o entry termina en primer y segundo lugar, la combinación ganadora será el field y el ejemplar que llegue a tercer lugar.</li>
													<li>Si su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>No hay pagos de consolación.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En una carrera con cuatro (4) o menos ejemplares, no habrá apuesta de EXACTA y será reembolsable el Dinero.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Doble Selección (Pick 2)</b>
										</td>
										<td>El apostador cobrará la apuesta de doble selección si selecciona al ejemplar ganador del primer lugar en dos carreras consecutivas designadas con apuesta de doble selección.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es trescientas unidades (300 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de seis mil unidades (6.000 U.), sin importar las veces apostadas en el boleto</li>
													<li>Si su ejemplar es retirado de cualquiera de las dos (2) carreras designadas como «doble selección», el dinero de la apuesta será reembolsado, y no habrá pagos de consolación.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (ejemplo: problemas de energía eléctrica, cambio de pista, etc.), el dinero de la apuesta será reembolsado.</u>
													</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Trifecta</b>
										</td>
										<td>El apostador cobrará la apuesta de Trifecta si selecciona a los ejemplares ganadores del primer, segundo y tercer lugar en el orden exacto de llegada, en las carreras designadas con apuesta de Trifecta.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es dos mil unidades (2.000 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de diez mil unidades (10.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si en el evento su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo de origen pague con todos los ejemplares participantes en cualquiera de los tres (3) lugares, por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos de origen, no siendo aplicable el pago de este premio en nuestro Sitio Web de apuestas.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En una carrera con cinco (5) o menos ejemplares, no habrá apuesta de TRIFECTA, y se reembolsará el dinero.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Triple Selección (Pick 3)</b>
										</td>
										<td>El apostador cobrará la apuesta de triple selección si selecciona al ejemplar ganador del primer lugar en tres carreras consecutivas designadas con apuesta de triple selección.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es dos mil unidades (2.000 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de diez mil unidades (10.000U.), sin importar las veces apostadas en el boleto.</li>
													<li>No habrá reembolsos por los retirados.</li>
													<li>Si su ejemplar es retirado del evento, éste será sustituido por el primer ejemplar favorito del Hipódromo de origen al cierre de la carrera; y en caso, de que el ejemplar favorito resulte ganador, se pagará por el favorito ganador y por cada ejemplar retirado del evento.</li>
													<li>En caso de que una o más carreras sean canceladas, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo paga con todos los ejemplares participantes en cualquiera de las tres (3) carreras, por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos de origen, no siendo aplicable el pago de este premio en nuestro Sitio Web de apuestas.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>No hay acumulados.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (ejemplo: problemas de energía eléctrica, cambio de pista, etc.), y el hipódromo de origen pagare con todos los ejemplares participantes, nuestro Sitio Web de apuestas aplicará:</u>
														<ol>
															<li>Los resultados de pago del hipódromo de origen;</li>
															<li>Solo pagará los tickets con la combinación acertada;</li>
															<li>
																Pagará los ejemplares adicionales que hayan sido seleccionados por el apostador en la carrera donde
																hubo cambio de pista.
															</li>
														</ol>
													</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Superfecta</b>
										</td>
										<td>El apostador cobrará la apuesta de Superfecta si selecciona a los ejemplares ganadores del primer, segundo, tercero y cuarto lugar, en el orden exacto de llegada, en carreras designadas con apuesta de Superfecta.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es de tres mil unidades (3.000 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de diez mil unidades (10.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si en el evento su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo de origen paga con todos los ejemplares participantes en cualquiera de los cuatro (4) lugares, por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos de origen, no siendo aplicable el pago de este premio en nuestro Sitio Web de apuestas.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación en nuestro sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En una carrera con seis (6) o menos ejemplares, no habrá apuesta de SUPERFECTA, y se reembolsará el dinero.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Pick 4</b>
										</td>
										<td>El apostador cobrará la apuesta de Pick 4 si selecciona al ejemplar ganador del primer lugar en cuatro carreras consecutivas designadas con apuesta de pick 4.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es tres mil unidades (3.000 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de diez mil unidades (10.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>No habrá reembolsos por los retirados.</li>
													<li>Si su ejemplar es retirado del evento, éste será sustituido por el primer ejemplar favorito del hipódromo de origen al cierre de la carrera; y en caso, de que el ejemplar favorito resulte ganador, se pagará por el favorito ganador y por cada ejemplar retirado del evento.</li>
													<li>En caso de que una o más carreras sean canceladas, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el hipódromo de origen paga con todos los ejemplares participantes en cualquiera de las cuatro (4) carreras, por no haber combinación ganadora, esto equivale a premio de consolación para los hipódromos de origen, no siendo aplicable el pago de este premio en nuestro Sitio Web de apuestas.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el hipódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>No hay acumulados.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (ejemplo: problemas de energía eléctrica, cambio de pista, etc.), y el hipódromo de origen pagare con todos los ejemplares participantes, nuestro Sitio Web de apuestas aplicará:</u>
														<ol>
															<li>Los resultados de pago del hipódromo de origen;</li>
															<li>Solo pagará los tickets con la combinación acertada;</li>
															<li>Pagará los ejemplares adicionales que hayan sido seleccionados por el apostador en la carrera donde hubo cambio de pista.</li>
														</ol>
													</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Parlay</b>
										</td>
										<td>El apostador cobrará la apuesta de PARLAY si selecciona a dos o tres ejemplares en diferentes carreras y/o diferentes hipódromos y finalizan ganadores. La apuesta debe ser, ya sea a primer, segundo o tercer lugar, pero todos los ejemplares seleccionados deben estar en el mismo orden de apuesta, y las selecciones deberán finalizar en el orden elegido.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>
														Los límites de pagos en Parlay se figuran de la siguiente manera:
														<ul>
															<li>Primer lugar: 20 unidades por cada unidad apostada.</li>
															<li>Segundo lugar: 8 unidades por cada unidad apostada.</li>
															<li>Tercer lugar: 4 unidades por cada unidad apostada.</li>
														</ul>
													</li>
													<li>El límite de pagos es de ciento cincuenta unidades (150 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual de parlay es de 6.000 Unidades.</li>
													<li>El máximo número de ejemplares en un parlay es de tres (3), y el mínimo dos (2)</li>
													<li>Si su ejemplar es retirado, un parlay de tres (3) se convierte en un parlay de dos (2), y un parlay de dos (2) se convierte en apuesta derecha</li>
													<li>Entries y fields corren bajo un solo interés</li>
												</ul>
											</b>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
			<div>
				<b>REGLAS DE OPERACIÓN PARA LOS GALGODROMOS Y JAI ALAI</b>
			</div>
			<br />
			<table class="tabl">
				<thead>
					<tr>
						<th class="text-center">CLASE “C”</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<table class="tabl">
								<thead>
									<tr>
										<th class="text-center" colspan="2">Apuestas Derechas</th>
									</tr>
								</thead>
								<tbody style="background: #dbf4ff;">
									<tr>
										<td class="text-center">
											<b>Ganador</b>
										</td>
										<td>El apostador cobrará a Ganador si su ejemplar finalizó oficialmente en primer lugar.</td>
									</tr>
									<tr>
										<td class="text-center">
											<b>Place</b>
										</td>
										<td>El apostador cobrará a Place si su ejemplar finalizó oficialmente en primer o segundo lugar.</td>
									</tr>
									<tr>
										<td class="text-center">
											<b>Show</b>
										</td>
										<td>El apostador cobrará a Show si su ejemplar finalizó oficialmente en primer, segundo o tercer lugar.</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td colspan="2" class="text-left">
							<b>
								<ul>
									<li>
										Limites:
										<ul>
											<li>Primer lugar: 15 unidades por cada unidad apostada.</li>
											<li>Segundo lugar: 8 unidades por cada unidad apostada.</li>
											<li>Tercer lugar: 4 unidades por cada unidad apostada.</li>
										</ul>
									</li>
									<li>Entries y fields (llaves) corren bajo un solo interés.</li>
									<li>Si su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
								</ul>
							</b>
						</td>
					</tr>
					<tr>
						<td>
							<table class="tabl">
								<thead>
									<tr>
										<th class="text-center" colspan="2">Exóticas</th>
									</tr>
								</thead>
								<tbody>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Quiniela</b>
										</td>
										<td>El apostador cobrará la apuesta de quiniela si selecciona a los ejemplares ganadores del primer y segundo lugar en cualquier orden de llegada.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es cien unidades (100 U.) por cada unidad apostada.</li>
													<li>El pago máximo por Boleto virtual es de un mil unidades (1.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si un field o entry (misma llave) finalizan en primer y segundo lugar, la combinación ganadora serán el field y el ejemplar que finalice en tercer lugar.</li>
													<li>Si su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si hay un entry o field de 3 o más ejemplares, no habrá apuesta de quiniela.</li>
													<li>En caso de que no haya boleto ganador en el Galgódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>No hay pagos de consolación.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Exacta</b>
										</td>
										<td>El apostador cobrará la apuesta de exacta si selecciona a los ejemplares ganadores del primer y segundo lugar en el orden exacto de llegada.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es cien unidades (100 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de dos mil unidades (2.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si un field o entry termina en primer y segundo lugar, la combinación ganadora será el field y el ejemplar que llegue a tercer lugar.</li>
													<li>Si su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>En caso de que no haya boleto ganador en el Galgódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>No hay pagos de consolación.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Doble Selección (Pick 2)</b>
										</td>
										<td>El apostador cobrará la apuesta de doble selección si selecciona al ejemplar ganador del primer lugar en dos carreras consecutivas designadas con apuesta de doble selección.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es ciento cincuenta unidades (150 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de dos mil unidades (2.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si su ejemplar es retirado de cualquiera de las dos (2) carreras designadas como «doble selección», el dinero de la apuesta será reembolsado, y no habrá pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el Galgódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>No hay pagos de consolación.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (ejemplo: problemas de energía eléctrica, cambio de pista, etc.), el dinero de la apuesta será reembolsado.</u>
													</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Trifecta</b>
										</td>
										<td>El apostador cobrará la apuesta de Trifecta si selecciona a los ejemplares ganadores del primer, segundo y tercer lugar en el orden exacto de llegada, en las carreras designadas con apuesta de Trifecta.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es mil unidades (1.000 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de tres mil unidades (3.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si en el evento su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el Galgódromo de origen paga con todos los ejemplares participantes en cualquiera de los tres (3) lugares, por no haber combinación ganadora, esto equivale a premio de consolación para los Galgódromo de origen, no siendo aplicable el pago de este premio en nuestro sitio Web de apuestas.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el Galgódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En una carrera con cinco (5) o menos ejemplares, no habrá apuesta de TRIFECTA, y se reembolsará el dinero.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Triple Selección (Pick 3)</b>
										</td>
										<td>El apostador cobrará la apuesta de triple selección si selecciona al ejemplar ganador del primer lugar en tres carreras consecutivas designadas con apuesta de triple selección.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es mil unidades (1.000 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de tres mil unidades (3.000U.), sin importar las veces apostadas en el boleto.</li>
													<li>No habrá reembolsos por los retirados.</li>
													<li>Si su ejemplar es retirado del evento, éste será sustituido por el primer ejemplar favorito al cierre de la carrera; y en caso, de que el ejemplar favorito resulte ganador, se pagará por el favorito ganador y por cada ejemplar retirado del evento.</li>
													<li>En caso de que una o más carreras sean canceladas, el dinero de la apuesta será reembolsado.</li>
													<li>Si en Galgódromo de origen paga con todos los ejemplares participantes en cualquiera de las tres (3) carreras, por no haber combinación ganadora, esto equivale a premio de consolación para los Galgódromo de origen, no siendo aplicable el pago de este premio en nuestro Sitio Web de apuestas.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el Galgódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>No hay acumulados.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (ejemplo: problemas de energía eléctrica, cambio de pista, etc.), y el Galgódromo de origen pagare con todos los ejemplares participantes, nuestro Sitio Web de apuestas aplicará:</u>
														<ol>
															<li>Los resultados de pago del hipódromo de origen;</li>
															<li>Solo pagará los tickets con la combinación acertada;</li>
															<li>
																Pagará los ejemplares adicionales que hayan sido seleccionados por el apostador en la carrera donde
																hubo cambio de pista.
															</li>
														</ol>
													</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Superfecta</b>
										</td>
										<td>El apostador cobrará la apuesta de Superfecta si selecciona a los ejemplares ganadores del primer, segundo, tercero y cuarto lugar, en el orden exacto de llegada, en carreras designadas con apuesta de Superfecta.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es de mil quinientas unidades (1.500 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de cinco mil unidades (5.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>Si en el evento su ejemplar es retirado, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el Galgódromo de origen paga con todos los ejemplares participantes en cualquiera de los cuatro (4) lugares, por no haber combinación ganadora, esto equivale a premio de consolación para los Galgódromo de origen, no siendo aplicable el pago de este premio en nuestro Sitio Web de apuestas.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el Galgódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos virtuales con la combinación acertada en nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>En una carrera con seis (6) o menos ejemplares, no habrá apuesta de SUPERFECTA, y se reembolsará el dinero.</li>
												</ul>
											</b>
										</td>
									</tr>
									<tr style="background: #dbf4ff;">
										<td class="text-center">
											<b>Pick 4</b>
										</td>
										<td>El apostador cobrará la apuesta de Pick 4 si selecciona al ejemplar ganador del primer lugar en cuatro carreras consecutivas designadas con apuesta de pick 4.</td>
									</tr>
									<tr>
										<td colspan="2">
											<b>
												<ul>
													<li>El límite de pago es mil quinientas unidades (1.500 U.) por cada unidad apostada.</li>
													<li>El pago máximo por boleto virtual es de cinco mil unidades (5.000 U.), sin importar las veces apostadas en el boleto.</li>
													<li>No habrá reembolsos por los retirados.</li>
													<li>Si su ejemplar es retirado del evento, éste será sustituido por el primer ejemplar favorito del Galgódromo de origen al cierre de la carrera; y en caso, de que el ejemplar favorito resulte ganador, se pagará por el favorito ganador y por cada ejemplar retirado del evento.</li>
													<li>En caso de que una o más carreras sean canceladas, el dinero de la apuesta será reembolsado.</li>
													<li>Si en el Galgódromo de origen paga con todos los ejemplares participantes en cualquiera de las cuatro (4) carreras, por no haber combinación ganadora, esto equivale a premio de consolación para los Galgódromo de origen, no siendo aplicable el pago de este premio en nuestro Sitio Web de apuestas.</li>
													<li>No hay pagos de consolación.</li>
													<li>En caso de que no haya boleto ganador en el Galgódromo de origen, no habrá premio de consolación, solo se pagará el límite de pago a los boletos con la combinación acertada en los establecimientos afiliados a nuestro Sitio Web de apuestas, siendo igualmente aplicable el pago máximo.</li>
													<li>Entries y fields (llaves) corren bajo un solo interés.</li>
													<li>No hay acumulados.</li>
													<li>
														<u>Si en el evento se presentare situaciones o circunstancias no previstas en estas reglas, caso fortuito o de fuerza mayor (ejemplo: problemas de energía eléctrica, cambio de pista, etc.), y el Galgódromo de origen pagare con todos los ejemplares participantes, nuestro Sitio Web de apuestas aplicará:</u>
														<ol>
															<li>Los resultados de pago del hipódromo de origen;</li>
															<li>Solo pagará los tickets con la combinación acertada;</li>
															<li>Pagará los ejemplares adicionales que hayan sido seleccionados por el apostador en la carrera donde hubo cambio de pista.</li>
														</ol>
													</li>
												</ul>
											</b>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
			<b>POLÍTICAS GENERALES DE TODOS LOS JUEGOS HÍPICOS</b>
			<br />
			<br />
			<table class="tabl">
				<thead>
					<tr>
						<th class="text-center" colspan="2">GLOSARIO</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<b>Entry</b>
						</td>
						<td>Dos o más caballos propiedad del mismo establo o entrenados por el mismo entrenador se denominan como entry y se unen como un solo interés de apuesta, una apuesta a un caballo unido es una apuesta a todos los caballos con quién esté unido.</td>
					</tr>
					<tr>
						<td>
							<b>Field</b>
						</td>
						<td>Un caballo agrupado con otro(s) bajo un mismo interés donde el número de caballos que arrancan excede el número de intereses de apuesta que el tablero del hipódromo de origen pueda manejar, una apuesta a un caballo del field es una apuesta a todos los caballos del field común.</td>
					</tr>
				</tbody>
			</table>
			<br />
			<br />
			<table class="tabl">
				<tbody>
					<tr>
						<td>
							<b>Apuestas Futuras</b>
						</td>
						<td>Consisten en ciertos eventos como el Derby de Kentucky, Copa de Criadores, Triple Corona, etc.</td>
					</tr>
					<tr>
						<td colspan="2">
							<b>
								<ul>
									<li>Estas apuestas son consideradas como apuesta derecha, y pagan de acuerdo al monto con el que se realizó la apuesta.</li>
									<li>En caso de que un ejemplar sea retirado, no habrá devoluciones.</li>
									<li>Entries y fields (llaves) corren bajo un solo interés.</li>
								</ul>
							</b>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
			<br />
			<table class="tabl">
				<tbody>
					<tr>
						<td>
							<b>
								Eventos
								Especiales
							</b>
						</td>
						<td>Esta es una apuesta a ciertos eventos designados de relevancia internacional y/o Nacional.</td>
					</tr>
					<tr>
						<td colspan="2">
							<b>
								<ul>
									<li>Estas apuestas son consideradas Apuestas Derechas y aplicarán las reglas y límites de apuesta indicados en la Hoja de Apuestas correspondiente a cada evento específico.</li>
								</ul>
							</b>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
			<br />
			<table class="tabl">
				<tbody>
					<tr>
						<td>
							<b>Apuesta</b>
						</td>
						<td>Es cada jugada que el apostador realiza, la cual se evidencia en el módulo correspondiente a Jugadas realizadas en el perfil del usuario.</td>
					</tr>
				</tbody>
			</table>
			<br />
			<br />
			<table class="tabl">
				<tbody>
					<tr>
						<td>
							<b>Primer Ejemplar Favorito</b>
						</td>
						<td>Es el ejemplar más apostado al cierre de la carrera.</td>
					</tr>
					<tr>
						<td colspan="2">
							<b>
								<ul>
									<li>Es el ejemplar con mayor cantidad de apuestas recibidas en el hipódromo de origen al cierre de la carrera.</li>
									<li>Se considerará como el favorito al cierre de la carrera, para efecto de ejemplares retirados donde la apuesta condicione al favorito para el pago de la misma.</li>
								</ul>
							</b>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
			<br />
			<table class="tabl">
				<thead>
					<tr>
						<th colspan="2" class="text-center">POLÍTICA PARA LA TOMA DE APUESTAS EN CARRERAS</th>
					</tr>
					<tr>
						<th class="text-center">HIPÓDROMO CLASE</th>
						<th>POLÍTICA DE TOMA DE APUESTAS</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="text-center">
							<h1>A</h1>
						</td>
						<td>
							<ul>
								<li>
									Ganador / Place / Show
									<ul>
										<li>El límite de apuesta es de 3.000 unidades a Ganador, Place y Show por competidor.</li>
										<li>Por cada apuesta a un competidor se expide un solo boleto virtual.</li>
										<li>Estos límites aplican para uno o más competidores por cada carrera.</li>
									</ul>
								</li>
								<li>
									Exóticas
									<ul>
										<li>Apuestas menores a 100 unidades se hacen en un solo boleto virtual.</li>
										<li>Por cada apuesta a un competidor se expide un solo boleto virtual.</li>
										<li>Lo anterior se aplica por cada tipo de apuesta exótica (Quiniela, Exacta, Trifecta, Superfecta, Doble Selección, Triple Selección, Parlay, etc.)</li>
										<li>Estos límites aplican por cada tipo de apuesta exótica con combinación de competidores distintos por cada carrera.</li>
									</ul>
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td class="text-center">
							<h1>B</h1>
						</td>
						<td>
							<ul>
								<li>
									Ganador / Place / Show
									<ul>
										<li>El límite de apuesta derecha es de 500 unidades a Ganador, Place y Show por competidor.</li>
										<li>Por cada apuesta a un competidor se expide un solo boleto virtual.</li>
										<li>Estos límites aplican para uno o más competidores por cada carrera.</li>
									</ul>
								</li>
								<li>
									Exóticas
									<ul>
										<li>Apuestas menores a 100 unidades se hacen en un solo boleto virtual.</li>
										<li>Por cada apuesta a un competidor se expide un solo boleto virtual.</li>
										<li>Lo anterior se aplica por cada tipo de apuesta exótica (Quiniela, Exacta, Trifecta, Superfecta, Doble Selección, Triple Selección, Parlay, etc.)</li>
										<li>Estos límites aplican por cada tipo de apuesta exótica con combinación de competidores distintos por cada carrera.</li>
									</ul>
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td class="text-center">
							<h1>C</h1>
						</td>
						<td>
							<ul>
								<li>
									Ganador / Place / Show
									<ul>
										<li>El límite de apuesta derecha es de 200 unidades a Ganador, Place y Show por competidor.</li>
										<li>Por cada apuesta a un competidor se expide un solo boleto virtual.</li>
										<li>Estos límites aplican para uno o más competidores por cada carrera.</li>
									</ul>
								</li>
								<li>
									Exóticas
									<ul>
										<li>El límite de apuestas exóticas es de 100 unidades.</li>
										<li>Por cada apuesta a un competidor se expide un solo boleto virtual.</li>
										<li>Lo anterior se aplica por cada tipo de apuesta exótica (Quiniela, Exacta, Trifecta, Superfecta, Doble Selección, Triple Selección, Parlay, etc.)</li>
										<li>Estos límites aplican por cada tipo de apuesta exótica con combinación de competidores distintos por cada carrera.</li>
									</ul>
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<td class="text-center">
							<h1>D</h1>
						</td>
						<td>
							<ul>
								<li>
									Ganador / Place / Show
									<ul>
										<li>El límite de apuesta derecha es de 100 unidades a Ganador, Place y Show por competidor.</li>
										<li>Por cada apuesta a un competidor se expide un solo boleto virtual.</li>
										<li>Estos límites aplican para uno o más competidores por cada carrera.</li>
									</ul>
								</li>
								<li>
									Exóticas
									<ul>
										<li>El límite de apuestas exóticas es de 50 unidades.</li>
										<li>Por cada apuesta a un competidor se expide un solo boleto virtual.</li>
										<li>Lo anterior se aplica por cada tipo de apuesta exótica (Quiniela, Exacta, Trifecta, Superfecta, Doble Selección, Triple Selección, Parlay, etc.)</li>
										<li>Estos límites aplican por cada tipo de apuesta exótica con combinación de competidores distintos por cada carrera.</li>
									</ul>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
			<table class="tabl">
				<thead>
					<tr>
						<th class="text-center" colspan="4">CUADRO RESUMEN DE LA TOMA DE APUESTAS</th>
					</tr>
					<tr>
						<th class="text-center" colspan="4">CLASES DE HIPÓDROMOS Y GALGODROMOS</th>
					</tr>
					<tr>
						<th class="text-center">Clase “A”</th>
						<th class="text-center">Clase “B”</th>
						<th class="text-center">Clase “C”</th>
						<th class="text-center">Clase “D”</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Und 3.000 GANADOR
							<br />Und 3.000 PLACE
							<br />Und 3.000 SHOW
						</td>
						<td>
							Und 500 GANADOR
							<br />Und 500 PLACE
							<br />Und 500 SHOW
						</td>
						<td>
							Und 200 GANADOR
							<br />Und 200 PLACE
							<br />Und 200 SHOW
						</td>
						<td>
							Und 100 GANADOR
							<br />Und 100 PLACE
							<br />Und 100 SHOW
						</td>
					</tr>
					<tr>
						<th colspan="4" class="text-center">NOTA</th>
					</tr>
					<tr>
						<td colspan="4">
							<ul>
								<li>Und abreviación de unidades de apuestas.</li>
								<li>Número de boletos virtuales igual a Nº de apuestas, es decir, un boleto virtual equivale a una apuesta.</li>
								<li>Cuándo los clientes soliciten apuestas por encima de los límites máximos establecidos por la empresa, consultar la política para autorización a clientes especiales.</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
			<br />
			<table class="tabl">
				<thead>
					<tr>
						<th class="text-center" colspan="4">CUADRO RESUMEN DE LA TOMA DE APUESTAS GALGODROMOS</th>
					</tr>
					<tr>
						<th class="text-center" colspan="4">TODOS LOS GALGODROMOS SE CONSIDERAN CLASE “D”</th>
					</tr>
					<tr>
						<th class="text-center"></th>
						<th class="text-left">Derecha</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>APUESTA MAXIMA</td>
						<td>
							Und 100 GANADOR
							<br />Und 100 PLACE
							<br />Und 100 SHOW
						</td>
					</tr>
					<tr>
						<th colspan="4" class="text-center">LAS APUESTAS DE GALGODROMOS ESTAN LIMITADOS</th>
					</tr>
					<tr>
						<td colspan="4">
							<ul>
								<li>
									EL PAGO SE HARÁ DE LA SIGUIENTE FORMA:
									<ul>
										<li>GANADOR: 15 unidades por cada unidad apostada</li>
										<li>PLACE: 8 unidades por cada unidad apostada</li>
										<li>SHOW: 4 unidades por cada unidad apostada</li>
									</ul>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style>
.tabl {
	border: 1px solid #c0c0c0;
	border-collapse: collapse;
	padding: 5px;
	background: white;
	color: black;
	border-radius: 4px !important;
	width: 100%;
}
.tabl th {
	border: 1px solid #c0c0c0;
	padding: 5px;
	border-radius: 4px !important;
	font-size: 12px !important;
}
.tabl td {
	border: 1px solid #c0c0c0;
	padding: 5px;
	border-radius: 4px !important;
}
</style>